import * as React from "react";
import { Logos } from "./Logos";

const SVGBalder = React.forwardRef(({ name, position, phone = "", email, photo }, ref) => {
  const offset = photo?.height && photo?.width ? 140 - (140 * (photo.height / photo.width)) : 0;
  console.log("offset", offset);
    return (
      <div ref={ref} className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="600" height="350" viewBox="0 0 600 350">
          <defs>
            <style>
              @import "https://fonts.googleapis.com/css2?family=Finlandica:ital,wght@0,400..700;1,400..700&display=swap'";
            </style>
          </defs>
          <rect width="100%" height="100%" fill="#254B53"/>
          <g id="logo" transform="scale(0.5) translate(50, 40)">
            <g>
              <g>
                <g>
                  <g>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M135.63,46.76c-.58-.58-1.75-1.32-3.21-1.32v-.07c1.03,0,2.12-.74,2.55-1.25.45-.58.66-1.31.66-2.12,0-.65-.15-1.16-.43-1.68-.29-.51-.66-.95-1.24-1.32-.59-.37-1.25-.66-2.12-.88-.88-.21-1.91-.29-3.07-.29h-7.1v.15c.3.29,1.03,1.09,1.03,3.73v8.2c0,2.63-.72,3.51-1.03,3.73v.15h7.82c2.41,0,4.24-.37,5.33-1.17,1.09-.8,1.68-1.9,1.68-3.22.14-1.09-.22-1.98-.88-2.64ZM126.14,39.52h2.99c1.1,0,1.91.21,2.49.66.58.44.8,1.02.8,1.82,0,1.83-1.09,2.71-3.29,2.71h-2.99v-5.2ZM132.42,51.37c-.58.44-1.46.66-2.63.66h-3.65v-5.56h3.65c1.17,0,2.05.22,2.63.73.58.45.88,1.17.88,2.12,0,.88-.3,1.61-.88,2.06Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M150.48,53.78h4.32l-4.9-12.22c-1.03-2.64-.8-3.44-.51-3.73v-.07h-5.11v.15c.29.29.5,1.09-.51,3.73l-4.98,12.14h2.12v-.15c-.29-.22-.58-1.11.45-3.66l.07-.15h8.62c1.03,2.64.73,3.51.44,3.73v.22ZM142.15,48.15l3.42-8.42h.22l3.44,8.42h-7.08Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M169.38,50.99v.08c-.29.29-1.09,1.03-3.71,1.03h-4.24v-10.39c0-2.64.72-3.51,1.02-3.73v-.15h-5.4v.15c.29.29,1.02,1.09,1.02,3.73v8.19c0,2.64-.73,3.52-1.02,3.73v.15h12.42v-2.78h-.08Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M178.04,37.83h-6.21v.15c.29.29,1.03,1.09,1.03,3.73v8.19c0,2.64-.74,3.52-1.03,3.73v.15h6.21c1.38,0,8.4,0,8.4-7.97s-7.02-7.98-8.4-7.98ZM178.33,52.1h-2.12v-12.51h2.12c.8,0,4.82,0,4.82,6.29s-4.09,6.22-4.82,6.22Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M197.99,39.58c2.63,0,3.44.73,3.73,1.03h.16v-2.78h-12.43v.15c.29.29,1.03,1.09,1.03,3.73v8.19c0,2.64-.74,3.52-1.03,3.73v.15h12.43v-2.78h-.16c-.29.3-1.09,1.03-3.73,1.03h-4.23v-5.56h5.77v-1.68h-5.77v-5.2h4.23Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M215.11,47.56c1.75-.51,3.57-1.83,3.57-4.76,0-4.97-5.62-4.97-6.86-4.97h-7.08v.15c.29.29,1.02,1.09,1.02,3.73v8.19c0,2.64-.73,3.52-1.02,3.73v.15h5.4v-.15c-.29-.29-1.02-1.09-1.02-3.73v-1.98h2.91l1.32,1.98c1.76,2.64,1.83,3.44,1.54,3.73v.15h4.24v-.21l-4.02-6ZM211.82,46.24h-2.78v-6.66h2.78s3.5-.08,3.5,3.22c0,3.59-3.5,3.44-3.5,3.44Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M243.91,50.99v.08c-.29.29-1.09,1.03-3.73,1.03h-4.24v-10.39c0-2.64.73-3.51,1.03-3.73v-.15h-5.41v.15c.29.29,1.03,1.09,1.03,3.73v8.19c0,2.64-.74,3.52-1.03,3.73v.15h12.42v-2.78h-.07Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M256.33,37.8v.15c.29.29,1.03,1.09,1.03,3.73v4.1c0,3.28-.08,6.73-3.8,6.73s-3.73-3.44-3.73-6.73v-4.1c0-2.64.74-3.51,1.03-3.73v-.15h-5.56v.15c.3.29,1.03,1.09,1.03,3.73v4.1c0,5.99,2.55,8.41,7.31,8.41s7.31-2.42,7.31-8.41v-4.1c0-2.64.72-3.51,1.02-3.73v-.15h-5.62Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M273.71,53.78h4.31l-5.99-8.93,5.04-7.09h-2.55v.15c.29.29.21,1.09-1.62,3.73l-1.38,2.04h-.22l-1.38-2.04c-1.83-2.64-1.9-3.52-1.61-3.73v-.15h-4.32l5.78,8.48-5.12,7.46h2.12v-.15c-.29-.29-.21-1.09,1.68-3.73l1.75-2.49h.22l1.67,2.49c1.83,2.64,1.91,3.51,1.62,3.73v.22Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M291.06,37.8v.15c.3.29,1.03,1.09,1.03,3.73v4.1c0,3.28-.07,6.73-3.8,6.73s-3.71-3.44-3.71-6.73v-4.1c0-2.64.72-3.51,1.02-3.73v-.15h-5.56v.15c.3.29,1.03,1.09,1.03,3.73v4.1c0,5.99,2.55,8.41,7.31,8.41s7.31-2.42,7.31-8.41v-4.1c0-2.64.73-3.51,1.03-3.73v-.15h-5.63Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M309.8,47.56c1.75-.51,3.58-1.83,3.58-4.76,0-4.97-5.62-4.97-6.87-4.97h-7.08v.15c.29.29,1.03,1.09,1.03,3.73v8.19c0,2.64-.74,3.52-1.03,3.73v.15h5.41v-.15c-.3-.29-1.03-1.09-1.03-3.73v-1.98h2.92l1.32,1.98c1.75,2.64,1.83,3.44,1.53,3.73v.15h4.24v-.21l-4.02-6ZM306.51,46.24h-2.78v-6.66h2.78s3.51-.08,3.51,3.22c0,3.59-3.51,3.44-3.51,3.44Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M324.28,46.82l5.69-8.99h-2.55v.15c.22.22.37.73-.29,2.12l-3.58,5.85h-.21l-3.15-5.27c-.95-1.83-.88-2.48-.66-2.7v-.15h-4.31l5.69,9.28v2.86c0,2.56-.73,3.36-1.02,3.65v.15h5.4v-.15c-.29-.29-1.02-1.09-1.02-3.65v-3.15Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M134.02,79.56v.08c-.29.29-1.09,1.02-3.73,1.02h-4.24v-10.38c0-2.64.74-3.52,1.03-3.73v-.16h-5.41v.16c.3.29,1.03,1.09,1.03,3.73v8.19c0,2.64-.72,3.51-1.03,3.73v.15h12.43v-2.78h-.08Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M144.5,65.91c-5.62,0-8.4,3.44-8.4,8.41s2.86,8.12,7.74,8.41h1.32c4.82-.21,7.74-3.22,7.74-8.41s-2.77-8.41-8.4-8.41ZM144.5,81.06c-3.79,0-4.67-2.06-4.67-6.74s.88-6.8,4.67-6.8,4.67,2.06,4.67,6.8-.8,6.74-4.67,6.74Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M162.13,66.39h-6.2v.16c.29.29,1.02,1.09,1.02,3.73v8.19c0,2.64-.73,3.51-1.02,3.73v.15h6.2c1.39,0,8.4,0,8.4-7.98s-7.01-7.98-8.4-7.98ZM162.42,80.65h-2.12v-12.5h2.12c.8,0,4.83,0,4.83,6.29s-4.09,6.21-4.83,6.21Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M182.01,75.06h3.07v5.12s-.95.88-3,.88c-.87,0-4.82-.37-4.82-6.74,0-3.65,1.09-6.73,4.82-6.73,1.1,0,1.97.21,2.71.51,1.83.87,1.75,2.48,1.75,2.48l.08.08,1.75-2.12c-.88-.8-2.49-2.64-6.36-2.64-6.28,0-8.4,3.73-8.4,8.41,0,6,3.65,8.41,8.4,8.41,3.65,0,5.48-1.82,6.36-2.63v-6.88h-6.36v1.83Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M200.21,68.15c2.63,0,3.44.73,3.73,1.02h.15v-2.78h-12.42v.16c.29.29,1.02,1.09,1.02,3.73v8.19c0,2.64-.72,3.51-1.02,3.73v.15h12.42v-2.78h-.15c-.29.29-1.09,1.03-3.73,1.03h-4.24v-5.56h5.78v-1.68h-5.78v-5.2h4.24Z"/>
                  </g>
                  <text
                    className="cls-1"
                    fontFamily="Finlandica-Regular, Finlandica"
                    fill="#cabaa0"
                    transform="translate(217.96 72.87)"
                    fontSize="8px"
                  >
                    <tspan className="cls-4" letterSpacing="0.4em" x="0" y="0">LAPLANDIA</tspan>
                    <tspan className="cls-4" letterSpacing="0.4em">
                      <tspan x="0" y="8.94">IS WONDERFULL</tspan>
                    </tspan>
                  </text>
                </g>
                <g>
                  <g>
                    <polygon className="cls-2"
                             fill="#cabaa0"
                             points="97.51 85.69 40.91 85.69 40.91 42.9 47.6 42.9 47.6 78.98 92.62 78.98 92.83 78.31 56.75 52.79 66.47 40.81 66 39.84 36.07 39.84 36.07 33.13 70.23 33.13 74.33 41.75 66.46 51.45 100.69 75.67 97.51 85.69"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M47.6,21.29v15.18h-6.69v-8.48h-12.63c-2.73,0-5.38-.38-7.89-1.09C10.37,24.09,2.53,16.02,0,5.87L5.86,0c.62,11.85,10.44,21.29,22.42,21.29h19.32Z"/>
                    <path className="cls-2"
                          fill="#cabaa0"
                          d="M25,33.82h-6.69c0-6.91,5.61-12.53,12.51-12.53v6.7c-3.21,0-5.82,2.61-5.82,5.83Z"/>
                  </g>
                  <polygon className="cls-2"
                           fill="#cabaa0"
                           points="100.11 33.13 100.11 68.67 93.41 63.95 93.41 39.84 80.03 39.84 76.84 33.13 100.11 33.13"/>
                </g>
              </g>
              <g>
                <path className="cls-2"
                      fill="#cabaa0"
                      d="M31.78,36.1c0-.48-.35-.78-.93-.78h-.86v2.22h.31v-.68h.54c.05,0,.1,0,.14-.01l.48.69h.34l-.52-.74c.31-.13.49-.36.49-.7ZM30.83,36.6h-.53v-1h.53c.42,0,.62.18.62.51s-.21.49-.62.49Z"/>
                <path className="cls-2"
                      fill="#cabaa0"
                      d="M30.82,34.4c-1.15,0-2.07.93-2.07,2.08s.92,2.08,2.07,2.08,2.07-.92,2.07-2.08-.93-2.08-2.07-2.08ZM30.82,38.21c-.96,0-1.72-.78-1.72-1.73s.76-1.73,1.72-1.73,1.72.78,1.72,1.73-.78,1.73-1.72,1.73Z"/>
              </g>
            </g>
          </g>

          <text x="45" y="100" textAnchor="left" fontFamily="Finlandica-Regular, Finlandica" fontSize="25"
                fill="white">{name}</text>
          <text x="45" y="120" textAnchor="left" fontFamily="Helvetica" fontSize="16" fill="#cabaa0">{position}</text>

          <text x="45" y="160" fontFamily="Helvetica" fontSize="16" fill="white">
            <a href={`mailto:${email}`} fill="white">{email}</a>
          </text>

          <text x="45" y="178" fontFamily="Helvetica" fontSize="16" fill="white">
            <a href={`tel:${phone.replaceAll(' ', '')}`} fill="white">{phone}</a>
          </text>

          {photo && <image x={405 - offset} y="40" height="140" href={photo.data}/>}
          <rect y="215" width="100%" height="200" fill="#ffffff"/>
          <Logos/>
        </svg>
      </div>
    )
  }
)
export default SVGBalder;
